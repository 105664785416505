import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterStorageService {

  constructor() { }
  //  mvn  
  save(data){
    this.delete()
    console.log(data)
    if(data.broker) {
      localStorage.setItem('broker',data.broker);      
    }
    if(data.broker_name) {
      localStorage.setItem('broker',data.broker_name);      
    }
    if(data.industry) {
      localStorage.setItem('industry',data.industry);      
    }
    if(data.category) {
      localStorage.setItem('category',data.category);
    }
    if(data.max_range) {
      localStorage.setItem('max_range', data.max_range);
    }
    if(data.min_range) {
      localStorage.setItem('min_range', data.min_range);
    }
    if(data.pe_firm_id) {
      localStorage.setItem('pe_firm_id',data.pe_firm_id);
    }
    if(data.pe_firm_status) {
      localStorage.setItem('pe_firm_status',data.pe_firm_status);
    }
    if(data.portfolio_id) {
      localStorage.setItem('portfolio_id',data.portfolio_id);
    }
    if(data.portfolio_status) {
      localStorage.setItem('portfolio_status',data.portfolio_status);
    }
    if(data.portfolio_status_id) {
      localStorage.setItem('portfolio_status',data.portfolio_status_id);
    }
    if(data.start_date) {
      localStorage.setItem('start_date',JSON.stringify(data.start_date));
    }
    if(data.end_date) {
      localStorage.setItem('end_date',JSON.stringify(data.end_date));
    }
  }
  
  delete() {
    let keysToRemove = [
      'pe_firm_id', 
      'pe_firm_status', 
      'portfolio_id', 
      'portfolio_status', 
      'broker', 
      'category', 
      'industry', 
      'max_range', 
      'min_range', 
      'vendor',
      'start_date',
      'end_date'
    ];
    keysToRemove.forEach(k => localStorage.removeItem(k))
  }
}
